import React from 'react';

import i18n from '@/translate/i18n';
import { HashLink } from '@/components/atoms/Typography/styles';
import heroImage from '@/assets/phones-and-cards.png';

import MerchantHeader from '../MerchantHeader';

import {
  AbsoluteWrapper,
  ContactUsButton,
  Container,
  ContentWrapper,
  HeroImage,
  OutlineButton,
  Title,
} from './style';

function MerchantHeroSection() {
  return (
    <Container>
      <HeroImage src={heroImage} alt="hero image" />
      <AbsoluteWrapper>
        <MerchantHeader bg="transparent" />
        <ContentWrapper>
          <OutlineButton>Integração rápida</OutlineButton>
          <Title size="6xl" color="white">
            {i18n.t('heroMerchant.title')}
          </Title>
          <HashLink to="#contact">
            <ContactUsButton>
              {i18n.t('heroMerchant.contactUs')}
            </ContactUsButton>
          </HashLink>
        </ContentWrapper>
      </AbsoluteWrapper>
    </Container>
  );
}

export default MerchantHeroSection;
