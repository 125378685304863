import React from 'react';
import { Trans } from 'react-i18next';
import MerchantHeader from '../MerchantHeader';
import heroImage from '@/assets/client-hero.png';
import {
  AbsoluteMobileWrapper,
  AbsoluteWrapper,
  ContactUsButton,
  Container,
  ContentWrapper,
  HeroImage,
  HeroImageMobile,
  Title,
} from './style';
import i18n from '@/translate/i18n';
import { FlexDiv } from '@/components/atoms/Containers/styles';
import { Text } from '@/components/atoms/Typography/styles';
import { HideInMobile } from '../MerchantHeader/styles';

function HeroSection() {
  return (
    <Container>
      <HeroImage src={heroImage} alt="hero image" />
      <AbsoluteWrapper>
        <MerchantHeader bg="transparent" />
        <ContentWrapper>
          <FlexDiv direction="column" gap="1vh">
            <Title size="6xl" color="white" className="title">
              {i18n.t('hero.title')}
            </Title>
            <Text
              color="white"
              variant="secondary"
              className="description"
              size="lg"
            >
              <Trans
                i18n={i18n}
                i18nKey="hero.description"
                components={[<strong />]}
              />
            </Text>
          </FlexDiv>
          <HideInMobile>
            <ContactUsButton>
              <a
                href="https://wallet.vpag.com/signup"
                target="_blank"
                rel="noreferrer"
                className="non-decoration"
              >
                {i18n.t('hero.button')}
              </a>
            </ContactUsButton>
          </HideInMobile>
        </ContentWrapper>
        <AbsoluteMobileWrapper>
          <HeroImageMobile src={heroImage} alt="hero image" />
          <ContactUsButton>
            <a
              href="https://wallet.vpag.com/signup"
              target="_blank"
              rel="noreferrer"
              className="non-decoration"
            >
              {i18n.t('hero.button')}
            </a>
          </ContactUsButton>
        </AbsoluteMobileWrapper>
      </AbsoluteWrapper>
    </Container>
  );
}

export default HeroSection;
