import styled from 'styled-components';

export const Button = styled.div`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 2rem;
  top: 3.5rem;
  z-index: 10;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
