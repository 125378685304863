import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1070px;
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    .title {
      font-size: 35px;
      line-height: 2.2rem;
    }
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 5rem 3rem;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 5rem 1rem;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 1200px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
