import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';
import textContent from './languages';

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'pt');
}

i18n.use(initReactI18next).init({
  debug: false,
  defaultNS: ['translations'],
  fallbackLng: 'pt',
  lng: 'pt',
  ns: ['translations'],
  resources: textContent,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
