import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  min-height: 55vh;
  overflow: hidden;
  padding: 4rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 5rem 0 2rem;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  position: relative;
  width: 60%;
  text-align: center;

  .title {
    font-size: 28px;
  }

  p {
    max-width: 450px;
  }

  img {
    width: 300px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .title {
      font-size: 3.5rem;
    }
    img {
      margin-top: -2rem !important;
      width: 60%;
    }
  }
`;
