import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from '@/translate/i18n';

import MerchantMenuBtn from '@/components/atoms/Button/MerchantMenuBtn';
import MobileModal from '@/components/organisms/MobileModal';
import useOnClickOutside from '@/components/hooks/useClickOutside';
import { FlexDiv } from '@/components/atoms/Containers/styles';

import {
  Container,
  ContentWrapper,
  HideInMobile,
  Logo,
  NavButton,
  WalletButton,
} from './styles';

const TIMEOUT = 500;

function Header({ bg }) {
  const isMerchant = window.location.pathname.includes('business');
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);
  useOnClickOutside(ref, setShowMenu);

  useEffect(() => {
    const timer = setTimeout(() => {
      const currentLocation = window.location.href;
      const hasHashParam = currentLocation.includes('#');

      if (hasHashParam) {
        const paramId = `${currentLocation.substring(
          currentLocation.indexOf('#') + 1,
        )}`;
        const element = document.getElementById(paramId);
        if (element) {
          element.scrollIntoView();
        }
      }
    }, TIMEOUT);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showMenu]);

  return (
    <Container bg={bg} ref={ref}>
      <ContentWrapper>
        <FlexDiv gap="3rem">
          <Link to="/">
            <Logo />
          </Link>
          <HideInMobile>
            <FlexDiv gap="0.5rem">
              <NavButton to="/" isActive={!isMerchant}>
                {i18n.t('header.forYou')}
              </NavButton>
              <NavButton to="/business" isActive={isMerchant}>
                {i18n.t('header.forMerchants')}
              </NavButton>
            </FlexDiv>
          </HideInMobile>
        </FlexDiv>

        <HideInMobile>
          <FlexDiv gap="0.5rem">
            <a
              href="https://wallet.vpag.com/signup"
              target="_blank"
              rel="noreferrer"
            >
              <WalletButton isActive>{i18n.t('header.signup')}</WalletButton>
            </a>
            <a
              href="https://wallet.vpag.com/signin"
              target="_blank"
              rel="noreferrer"
            >
              <WalletButton>{i18n.t('header.access')}</WalletButton>
            </a>
          </FlexDiv>
        </HideInMobile>
      </ContentWrapper>
      <MerchantMenuBtn onClick={() => setShowMenu((state) => !state)} />
      <MobileModal
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        isBusinessMenu
      />
    </Container>
  );
}

Header.propTypes = {
  bg: PropTypes.string,
};
export default Header;
