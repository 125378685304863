import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  background: ${({ theme }) => theme.colors.primary};
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1070px;
  padding: 2rem 0;
  width: 100%;

  p {
    margin-bottom: 2rem;
    max-width: 90%;
    text-align: center;
    font-size: 36px;
    line-height: 2.5rem;
  }

  .form {
    margin-top: 15rem;
    position: relative;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .form {
      margin-top: 15rem;
      height: 150px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 2rem;

    .form {
      margin-top: 10rem;
      margin-right: 30%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    p {
      font-size: 4rem;
      max-width: 55%;
      text-align: left;
      line-height: 4rem;
    }
  }
`;
