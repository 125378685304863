import React from 'react';

import i18n from '@/translate/i18n';
import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { Text } from '@/components/atoms/Typography/styles';

import { ReactComponent as SyncIcon } from '@/assets/icons/sync.svg';
import { ReactComponent as MoneySyncIcon } from '@/assets/icons/money-sync.svg';
import { ReactComponent as BadgeIcon } from '@/assets/icons/badge.svg';
import { ReactComponent as SupportIcon } from '@/assets/icons/support.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { ReactComponent as CommunityIcon } from '@/assets/icons/community.svg';

import { CardsWrapper, Container, ContentWrapper } from './styles';
import BenefitCard from '@/components/molecules/BenefitCard';

function FlashAdvantageSection() {
  const content = i18n.t('flashAdvantageSection.content', {
    returnObjects: true,
  });

  const icons = {
    sync: SyncIcon,
    moneySync: MoneySyncIcon,
    badge: BadgeIcon,
    support: SupportIcon,
    plus: PlusIcon,
    community: CommunityIcon,
  };

  return (
    <DefaultContainer bgColor="light">
      <Container>
        <ContentWrapper>
          <Text size="5xl" lineHeight="3.5rem" className="title">
            {i18n.t('flashAdvantageSection.title')}
          </Text>
          <CardsWrapper>
            {content.map(({ title, description, icon }) => (
              <BenefitCard
                title={title}
                description={description}
                icon={icons[icon]}
                key={title}
              />
            ))}
          </CardsWrapper>
        </ContentWrapper>
      </Container>
    </DefaultContainer>
  );
}

export default FlashAdvantageSection;
