import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 5rem 1rem;
  text-align: center;
  max-width: 1070px;
  width: 80%;

  img {
    width: 95px;
  }
`;

export const TextSpacing = styled.div`
  padding: 0 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 16rem;
  }
`;

export const Item = styled.div`
  padding: 2rem 3rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 40px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 10rem;
    border-radius: 80px;
  }
`;
