export default [
  {
    title: 'SBC Summit\nRio',
    schedule: '25 - 27 February 2025',
    location: 'Rio de Janeiro, Brazil',
    image: '/assets/events/summit-rio.png',
    url: '/#contact',
  },
  {
    title: 'Bis Sigma\nAmericas',
    schedule: '04 - 10 April 2025',
    location: 'São Paulo, Brazil',
    image: '/assets/events/sigma-americas.png',
    url: '/#contact',
  },
];
