import React from 'react';

import i18n from '@/translate/i18n';
import {
  DefaultContainer,
  FlexDiv,
} from '@/components/atoms/Containers/styles';
import coinIcon from '@/assets/icons/hero/coin.svg';
import centralBankIcon from '@/assets/icons/hero/central-bank.svg';
import giftIcon from '@/assets/icons/hero/gift.svg';
import starIcon from '@/assets/icons/hero/star.svg';

import { ContentWrapper, BenefitIcon, BenefitBox } from './styles';
import { Text } from '@/components/atoms/Typography/styles';

function ClientBenefitsSection() {
  const content = i18n.t('benefits.content', {
    returnObjects: true,
  });
  const benefitsIcons = {
    0: coinIcon,
    1: giftIcon,
    2: starIcon,
    3: centralBankIcon,
  };
  return (
    <DefaultContainer bgColor="dark">
      <ContentWrapper>
        <FlexDiv justify="space-between" width="100%" wrap>
          {content.map((item, index) => (
            <BenefitBox key={item}>
              <BenefitIcon>
                <img src={benefitsIcons[index]} alt={item} />
              </BenefitIcon>
              <Text text="lg" color="white">
                {item}
              </Text>
            </BenefitBox>
          ))}
        </FlexDiv>
      </ContentWrapper>
    </DefaultContainer>
  );
}

export default ClientBenefitsSection;
