import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: baseline;
  background-color: ${({ theme }) => theme.colors.bg.primary};
  border-radius: 5px;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 10% 90%;
  height: auto;
  gap: 0.2rem;
  min-width: 320px;
  padding: 25px 0 25px 25px;
  text-align: left;
  width: 250px;
  padding-right: 3rem !important;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-right: 1rem;
  }

  & div p:first-of-type {
    padding-right: 1rem;
    font-size: 18px;
    line-height: 1rem;
    margin-bottom: 0.2rem;
  }
  p {
    margin-top: -0.2rem;
    font-size: 14px;
  }

  svg {
    height: 22px;
    width: 22px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 350px;
    height: 150px;
    padding: 25px 0 0 25px;
  }
`;
