import React from 'react';

import { Container, ContentWrapper } from './styles';
import { Text } from '@/components/atoms/Typography/styles';
import productsImage from '@/assets/phones-and-cards.png';

function DescriptionSection() {
  return (
    <Container>
      <ContentWrapper>
        <Text size="5xl" className="title">
          A Conta digital do apostador
        </Text>
        <img src={productsImage} alt="products" />
      </ContentWrapper>
    </Container>
  );
}

export default DescriptionSection;
