import styled from 'styled-components';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import { HeroTitle } from '@/components/atoms/Typography/styles';

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 10vh;
  }
`;

export const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  padding-top: 5rem;
  max-width: 1070px;
  width: 80%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 6vh;
    padding: 0 30rem 0 0;
  }
`;

export const AbsoluteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15vh;
  height: 100%;
  position: absolute;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 10vh;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 0 2rem;
  }
`;

export const HeroImage = styled.img`
  width: 110%;
  bottom: 0;
  margin-top: 13vh;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    position: absolute;
    right: calc(100vw - 90%);
    mask-image: linear-gradient(to left, black 50%, rgba(0, 0, 0, 0));
    top: 15%;
    margin-top: 0;
    width: 650px;
    bottom: inherit;
    display: block;
    animation: fadeIn 1s ease-in-out forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ContactUsButton = styled(ContactButton)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: auto;
    padding: 1rem 1rem;
    width: 100%;
    margin-top: 1rem;
  }
`;

export const Title = styled(HeroTitle)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    line-height: 3.5rem;
  }
`;

export const OutlineButton = styled.div`
  border: 1px solid #bcdc6e;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin-bottom: -1rem;
  max-width: fit-content;
  font-size: 14px;
  background-color: black;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: -2rem;
  }
`;
