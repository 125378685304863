import styled, { css } from 'styled-components';

import { ReactComponent as VpagFlashLogo } from '@/assets/vpag-flash-logo.svg';
import { ReactComponent as VpagWalletLogo } from '@/assets/vpag-wallet-log.svg';
import { DefaultContainer } from '@/components/atoms/Containers/styles';

const LogoStyles = css`
  max-width: 260px;
  width: 100%;
`;

export const FlashLogo = styled(VpagFlashLogo)`
  ${LogoStyles}
`;
export const WalletLogo = styled(VpagWalletLogo)`
  ${LogoStyles}
`;

export const Container = styled(DefaultContainer)`
  padding: 5rem 0;
`;

export const DescriptionContainer = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-right: 4.5rem;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  width: 100%;
  padding: 0 3rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 8vh;
    padding: 0;
  }
`;

export const ItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;

  button {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

    button {
      display: block;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  align-items: flex-start;

  .title {
    margin-bottom: 2rem;
    font-size: 40px !important;
  }

  .description {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 1.5rem !important;
  }

  svg {
    width: 200px;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .description {
      font-size: 25px;
    }
    & > p {
      text-transform: inherit !important;
    }

    svg {
      width: 200px;
      margin-bottom: 2rem;
    }
  }
`;

export const Image = styled.img`
  max-height: 400px;
  max-width: 370px;
  width: 100%;
`;

export const LargeImage = styled.img`
  max-height: 450px;
  max-width: 450px;
  width: 100%;
  margin: 4rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0;
  }
`;

export const HideInDesktop = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
