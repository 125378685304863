import React, { useEffect } from 'react';

import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';

import { Container, Wrapper } from './styles';

function GetInTouchSection() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.popt.in/pixel.js?id=c30b69a06fe64';
    script.async = true;
    script.id = 'pixel-script-poptin';
    script.onload = () => console.log('Form loaded');
    document.head.appendChild(script);
  }, []);
  return (
    <Container>
      <Wrapper>
        <Text size={i18n.t('getInTouch.titleSize')} lineHeight="4rem">
          {i18n.t('getInTouch.title')}
        </Text>
        <div className="poptin-embedded form" data-id="76adf3aa6c038" />
      </Wrapper>
    </Container>
  );
}

export default GetInTouchSection;
