import { createGlobalStyle } from 'styled-components';

import ArboriaBook from '@/fonts/Arboria-Book.ttf';
import ArboriaMedium from '@/fonts/Arboria-Medium.ttf';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Arboria Book';
    src: url(${ArboriaBook}) format('truetype');
  }

  @font-face {
    font-family: 'Arboria Medium';
    src: url(${ArboriaMedium}) format('truetype');
  }

  * {
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  *::-webkit-scrollbar {
      width: 10px;
    }

    *::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.bg.secondary};
      border-radius: 4px;
      opacity: 0.3;
    }

    *::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.info.strong};
      border: 1px solid ${({ theme }) => theme.colors.bg.secondary};
      border-radius: 5px;
    }

  #root {
    white-space: pre-line;
  }

  html, body, #root {
    background-color: #1d1d1b;
    color: #fff;
    font-family: 'Arboria Medium', sans-serif;
    height: 100%;
    min-height: 100%;
    width: 100vw;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  html {
    font-size: 14px;

    @media screen and (min-width: 1070px) {
      font-size: 16px;
    }
  }

  [disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  button {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      filter: brightness(0.6);
    }
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .hide-chat {
    display: none !important;
  }

  @keyframes goLeftAnimation {
    0% {
      transform: translateY(-200%);
    }

    100%{
      transform: translateY(0);
    }
  }

  @keyframes goRightAnimation {
    0% {
      transform: translateY(0);
    }

    100%{
      transform: translateY(-200%);
    }
  }

`;

export default GlobalStyle;
