import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0rem 1rem;
  text-align: center;
  max-width: 1070px;
  width: 80%;

  img {
    width: 80px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 5rem 1rem 2rem;
  }
`;

export const BenefitIcon = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 10px;
  background-color: #a1e600;
  display: grid;
  place-content: center;
  margin: auto;

  img {
    width: 40px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 100px;
    width: 100px;

    img {
      width: 50px;
    }
  }
`;

export const BenefitBox = styled.div`
  width: 100px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
`;
