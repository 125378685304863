import i18n from '@/translate/i18n';

export const endUserFooterNavLinks = [
  {
    label: i18n.t('footer.privacyPolicy'),
    path: '/privacy-policy',
  },
  {
    label: i18n.t('footer.responsibleGaming'),
    path: '/responsible-gaming',
  },

  {
    label: i18n.t('footer.termsAndConditions'),
    path: '/terms-and-conditions',
  },

  {
    label: i18n.t('footer.faq'),
    path: '/faq',
  },
  {
    label: i18n.t('footer.cookiesPolicy'),
    path: '/cookie-policy',
  },
  {
    label: i18n.t('footer.forMerchants'),
    path: '/business',
  },
];

export const businessFooterNavLinks = [
  {
    label: i18n.t('footer.developerIntegrationGuide'),
    children: [
      {
        label: i18n.t('footer.vpagFlash'),
        path: 'http://docs.gateway.vpag.com',
        externalLink: true,
      },
      {
        label: i18n.t('footer.vpagWallet'),
        path: 'http://docs.wallet.vpag.com',
        externalLink: true,
      },
    ],
  },
  {
    elements: [
      {
        label: i18n.t('footer.privacyPolicy'),
        path: '/business/privacy-policy',
        externalLink: true,
      },
      {
        label: i18n.t('footer.termsAndConditions'),
        path: '/business/terms-and-conditions',
        externalLink: true,
      },
    ],
  },
  {
    elements: [
      {
        label: i18n.t('footer.cookiesPolicy'),
        path: '/cookie-policy',
        externalLink: true,
      },
      {
        label: i18n.t('footer.responsibleGaming'),
        path: '/responsible-gaming',
      },
    ],
  },
];
