import React from 'react';

import i18n from '@/translate/i18n';
import vpagFlashAppImage from '@/assets/products/vpag-flash.png';
import vpagWalletAppImage from '@/assets/products/vpag-wallet.png';
import oneClickPixImage from '@/assets/products/oneclickpix.png';
import pixAndGoImage from '@/assets/products/pix-and-go.png';
import { Text } from '@/components/atoms/Typography/styles';

import {
  Container,
  ContentWrapper,
  DescriptionContainer,
  FlashLogo,
  ItemWrapper,
  LargeImage,
  TextWrapper,
  WalletLogo,
} from './styles';

function MerchantDescriptionSection() {
  const productPreview = {
    'Pix&Go': pixAndGoImage,
    'OneClick-Pix': oneClickPixImage,
    'vpag-wallet': vpagWalletAppImage,
    'vpag-flash': vpagFlashAppImage,
  };

  const productLogo = {
    'vpag-wallet': WalletLogo,
    'vpag-flash': FlashLogo,
  };

  const products = i18n.t('businessDescriptionSection.content', {
    returnObjects: true,
  });

  return (
    <Container>
      <ContentWrapper>
        {products.map((item, index) => {
          const ProductImage = productLogo[item.slug];
          return (
            <ItemWrapper reverse={index % 2 !== 0} key={item.id}>
              <LargeImage
                src={productPreview[item.slug]}
                alt="Vpag Wallet app"
              />
              <TextWrapper>
                {productLogo[item.slug] ? (
                  <ProductImage />
                ) : (
                  <Text size="2xl" weight="bold" capitalize className="title">
                    {item.slug.replaceAll('-', ' ')}
                  </Text>
                )}

                <Text
                  lineHeight="1.5rem"
                  weight="500"
                  size="xl"
                  className="description"
                >
                  {item.title}
                </Text>
                <DescriptionContainer>
                  <Text variant="secondary">{i18n.t(item.description)}</Text>
                </DescriptionContainer>
              </TextWrapper>
            </ItemWrapper>
          );
        })}
      </ContentWrapper>
    </Container>
  );
}

export default MerchantDescriptionSection;
