import React from 'react';

import Awards from '@/components/organisms/Awards';
import DescriptionSection from '@/components/organisms/DescriptionSection';
import Footer from '@/components/organisms/Footer';
import GetInTouchSection from '@/components/organisms/GetInTouchSection';
import HeroSection from '@/components/organisms/HeroSection';
import SloganSection from '@/components/organisms/SloganSection';
import ClientBenefitsSection from '@/components/organisms/ClientBenefitsSection';

function Home() {
  return (
    <>
      <HeroSection />
      <ClientBenefitsSection />
      <DescriptionSection />
      <SloganSection />
      <Awards />
      <GetInTouchSection />
      <Footer />
    </>
  );
}

export default Home;
