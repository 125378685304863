import styled from 'styled-components';

import {
  DefaultContainer,
  FlexDiv,
} from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  padding: 0.5rem 3rem 6rem;
  width: 100%;

  .logo {
    display: none;
  }

  .simple-logo {
    display: block;
    height: 8rem;
    width: 10rem;
  }

  .minimum-age {
    display: block;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 4.5rem 0 3rem;

    .minimum-age {
      bottom: 10%;
      left: 15%;
      position: absolute;
    }

    .logo {
      display: block;
      max-width: 250px;
    }

    .simple-logo {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1070px;
  position: relative;
  text-align: center;
  width: 70%;

  .icon-container {
    align-items: center !important;
    gap: 1rem;
    justify-content: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-start;
    flex-direction: row;
    text-align: left;

    .icon-container {
      align-items: flex-start !important;
      gap: 4rem;
      justify-content: center;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
  }
`;

export const Section = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.tertiary};
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100%;
`;

export const StackContainer = styled(FlexDiv)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const NavLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex-wrap: wrap;
  gap: 1rem;
  min-width: 300px;

  a {
    font-family: 400;
    p {
      font-size: 15px !important;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem 2rem;
  }
`;

export const MerchantNavLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  p {
    font-size: 15px !important;
  }

  .elements-with-out-guide {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
  }

  .products {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  ul {
    list-style: none;
    cursor: pointer;
  }

  li {
    text-decoration: underline;

    a {
      color: white;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    .elements-with-out-guide {
      display: flex;
      flex-wrap: wrap;
    }

    .products {
      max-width: 200px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    ul {
      list-style: inherit;
    }

    li {
      text-decoration: underline;
      margin-left: 2rem;
    }
  }
`;

export const InfoWrapper = styled.div`
  max-width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 50%;
  }
`;
