import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { X } from 'phosphor-react';

import {
  CloseButton,
  ExternalNavLink,
  Logo,
  MenuModalContainer,
  Nav,
  NavLink,
} from './styles';

import { endUserNavLinks } from './data';

const SIZE = 30;
const TIME_TO_EXECT = 500;

function MobileModal({ showMenu, setShowMenu, isBusinessMenu }) {
  const [hideAnimation, setHideAnimation] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHideAnimation(false);
    }, TIME_TO_EXECT);

    return () => {
      clearTimeout(timeout);
    };
  });

  const mapList = endUserNavLinks;

  return (
    <MenuModalContainer showMenu={showMenu} hideAnimation={hideAnimation}>
      <CloseButton type="button" onClick={() => setShowMenu(false)}>
        <X size={SIZE} />
      </CloseButton>
      <Logo />
      <Nav>
        {mapList.map(({ label, path, externalLink, target = '_blank' }) =>
          externalLink ? (
            <ExternalNavLink
              href={path}
              key={label}
              onClick={() => setShowMenu(false)}
              target={target}
            >
              {label}
            </ExternalNavLink>
          ) : (
            <NavLink to={path} key={label} onClick={() => setShowMenu(false)}>
              {label}
            </NavLink>
          ),
        )}
      </Nav>
    </MenuModalContainer>
  );
}

MobileModal.propTypes = {
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  isBusinessMenu: PropTypes.bool,
}.isRequired;

export default MobileModal;
