import React, { useEffect } from 'react';
import { Text } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';
import { Container } from './styles';

const PoptinForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.popt.in/pixel.js?id=c30b69a06fe64';
    script.async = true;
    script.id = 'pixel-script-poptin';
    script.onload = () => console.log('Form loaded');
    document.head.appendChild(script);
  }, []);
  return (
    <Container id="contact">
      <Text size="5xl" id="contact">
        {i18n.t('contactForm.title')}
      </Text>
      <div className="poptin-embedded" data-id="54c670ab284e3" />
    </Container>
  );
};

export default PoptinForm;
