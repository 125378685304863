import i18n from '@/translate/i18n';

export const endUserNavLinks = [
  {
    label: i18n.t('header.forYou'),
    path: '/',
  },
  {
    label: i18n.t('header.forMerchants'),
    path: '/business',
    externalLink: true,
    target: '',
  },
  {
    label: i18n.t('header.signup'),
    path: '/signup',
    externalLink: true,
    target: '',
  },
  {
    label: i18n.t('header.access'),
    path: '/signin',
    externalLink: true,
    target: '',
  },
];

export const businessNavLinks = [
  {
    label: i18n.t('header.about'),
    path: 'https://eightroom.com',
    externalLink: true,
  },
  {
    label: i18n.t('footer.developerIntegrationGuide'),
    path: '/business#integrationGuide',
  },
  {
    label: i18n.t('header.contact'),
    path: '/business#contact',
  },
];
