import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { ReactComponent as Logo } from '@/assets/logo.svg';
import { ReactComponent as SimpleLogo } from '@/assets/powered-vertical.svg';
import { ReactComponent as MinimumAgeIcon } from '@/assets/icons/minimum-age.svg';
import { NavLink, Text } from '@/components/atoms/Typography/styles';
import { FlexDiv } from '@/components/atoms/Containers/styles';

import {
  Container,
  ContentWrapper,
  InfoWrapper,
  MerchantNavLinkWrapper,
  NavLinksWrapper,
  Section,
  StackContainer,
} from './styles';
import { businessFooterNavLinks, endUserFooterNavLinks } from './data';
import { HideInMobile } from '../MerchantHeader/styles';

function Footer({ renderBusinessLinks }) {
  const i18nextKey = renderBusinessLinks ? 'merchantFooter' : 'footer';
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Container>
        <ContentWrapper>
          <FlexDiv
            direction="column"
            align="flex-start"
            gap="4rem"
            className="icon-container"
          >
            <StackContainer>
              <Logo className="logo" />
              <SimpleLogo className="simple-logo" />
            </StackContainer>
            <MinimumAgeIcon />
          </FlexDiv>
          <FlexDiv />
          <InfoWrapper>
            <FlexDiv direction="column" gap="2.5rem">
              <HideInMobile>
                <Text size="xs" color="white" variant="secondary">
                  A conta digital Vpag é operada pela Pay Instituição de
                  Pagamento S.A. instituição de pagamento autorizada pelo Banco
                  Central do Brasil, registrada sob o CNPJ n.º
                  36.690.516/0001-52 e com sede na Avenida Augusto Meyer, n.º
                  40, sala 504, Higienópolis, Porto Alegre, RS, CEP 90550-110,
                  Brasil.
                </Text>
              </HideInMobile>
              {renderBusinessLinks ? (
                <MerchantNavLinkWrapper>
                  {businessFooterNavLinks.map((item) =>
                    item.children ? (
                      <div className="products">
                        <Text color="white" size="base" variant="secondary">
                          {item.label}
                        </Text>
                        <ul>
                          {item.children.map((ch) => (
                            <li key={ch.label}>
                              <a
                                href={ch.path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Text
                                  color="white"
                                  size="base"
                                  variant="secondary"
                                >
                                  {ch.label}
                                </Text>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="elements-with-out-guide">
                        {item.elements.map((link) => (
                          <NavLink to={link.path} key={link.path}>
                            <Text
                              color="white"
                              size="base"
                              variant="secondary"
                              className="faq"
                            >
                              {link.label}
                            </Text>
                          </NavLink>
                        ))}
                      </div>
                    ),
                  )}
                </MerchantNavLinkWrapper>
              ) : (
                <NavLinksWrapper>
                  {endUserFooterNavLinks.map((item) => (
                    <NavLink to={item.path} key={item.path}>
                      <Text color="white" size="base" variant="secondary">
                        {item.label}
                      </Text>
                    </NavLink>
                  ))}
                </NavLinksWrapper>
              )}
            </FlexDiv>
          </InfoWrapper>
        </ContentWrapper>
      </Container>
      <Section>
        <Text color="light" size="sm" variant="secondary">
          ®{currentYear} {i18n.t(`${i18nextKey}.allRightsReserved`)}
        </Text>
      </Section>
    </>
  );
}

Footer.propTypes = {
  renderBusinessLinks: PropTypes.bool,
};

export default Footer;
