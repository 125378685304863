import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { ReactComponent as MainLogo } from '@/assets/simple-logo.svg';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  padding: 2rem 2rem 1rem;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-color: ${({ theme, bg }) => bg ?? theme.colors.bg.dark};
  }
`;

export const ContentWrapper = styled.header`
  align-items: center;
  align-self: start;
  display: flex;
  gap: 0.5rem;
  padding: 1.5rem 0;
  justify-content: space-between;
  max-width: 1070px;
  width: 80%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-self: center;
    padding: 2rem 0 0;
  }
`;

export const Logo = styled(MainLogo)`
  cursor: pointer;
  width: 120px;
`;

export const NavButton = styled(Link)`
  padding: 0.5rem 1.2rem;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : '#606060'};
  color: ${({ isActive }) => (isActive ? '#000' : '#fff')};
  border-radius: 20px;
  border: none;
  font-family: ${({ theme }) => theme.font.family.primary};
  font-size: 14px;
  text-decoration: none;
`;

export const WalletButton = styled.button`
  padding: 0.5rem 1.2rem;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : 'transparent'};
  color: ${({ isActive, isMerchant }) =>
    isActive || isMerchant ? '#000' : '#fff'};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.family.primary};
  font-size: 14px;
`;

export const HideInMobile = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;
