import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@/components/atoms/Typography/styles';
import { Wrapper } from './styles';

function BenefitCard({ title, description, icon: Icon }) {
  return (
    <Wrapper>
      <Icon />
      <div>
        <Text>{title}</Text>
        <Text size="sm" variant="secondary">
          {description}
        </Text>
      </div>
    </Wrapper>
  );
}

BenefitCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element,
};

export default BenefitCard;
