import styled from 'styled-components';
import { Swiper } from 'swiper/react';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)``;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1070px;
  padding: 4rem 0;
  text-align: center;
  width: 80%;

  img {
    max-width: 390px;
    width: 100%;
  }

  p {
    font-size: 30px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 8rem 5rem;

    p {
      font-size: 60px;
    }
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  text-align: center;

  p {
    font-size: 35px;
    line-height: 2.5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    max-width: 40%;
    text-align: left;

    p {
      line-height: 3.5rem;
      font-size: 4rem;
      max-width: 470px;
    }
  }
`;

export const SwiperWrapper = styled(Swiper)`
  padding-top: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 1070px;
  padding: 55px 0 50px;
  width: 100%;

  .swiper-pagination-bullet {
    background: transparent;
    border: 2px solid #464646;
    height: 6px;
    border-radius: 3px;
    width: 2.5rem;
  }

  .swiper-pagination-bullet-active {
    background: #464646;
  }

  .slideNextClass {
    background-color: red;
    width: 50px;
    height: 50px;
  }

  .swiper-btn-prev,
  .swiper-btn-next {
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.link.primary};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.info.dark};
    cursor: pointer;
    font-size: 25px;
    height: 36px;
    left: 38%;
    opacity: 1;
    padding: 5px;
    position: absolute;
    top: 0;
    width: 36px;
  }

  .swiper-btn-next {
    left: 50%;
  }

  .swiper-btn-prev.swiper-button-disabled,
  .swiper-btn-next.swiper-button-disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.link.primary};
  }

  .swiper-button-lock {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 65px 0 50px;

    .swiper-btn-prev,
    .swiper-btn-next {
      background-color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.link.primary};
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.info.dark};
      cursor: pointer;
      font-size: 25px;
      height: 36px;
      left: 0;
      opacity: 1;
      padding: 5px;
      position: absolute;
      top: 0;
      width: 36px;
    }

    .swiper-btn-next {
      left: 40px;
    }
  }
`;
