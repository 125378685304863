import styled from 'styled-components';

import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import { HeroTitle } from '@/components/atoms/Typography/styles';

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  height: 120vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: black;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 10vh;
  }
`;

export const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-top: -7rem;
  max-width: 1070px;
  width: 80%;

  .title {
    line-height: 3rem;
  }

  .description {
    padding-right: 8rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .title {
      line-height: 4.5rem;
    }
    margin-top: 0;
    gap: 6vh;
    padding: 0 45rem 0 0;
  }
`;

export const AbsoluteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15vh;
  height: 100%;
  position: absolute;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 20vh;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 0 2rem;
  }
`;

export const HeroImageMobile = styled.img`
  height: 75%;
  padding-left: 6rem;
  width: 140%;
  object-fit: cover;
  animation: fadeIn 1s ease-in-out forwards;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const HeroImage = styled.img`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    position: absolute;
    right: 0;
    mask-image: linear-gradient(to left, black 90%, rgba(0, 0, 0, 0));
    width: 60%;
    top: auto;
    display: block;
    animation: fadeIn 1s ease-in-out forwards;
    padding-top: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ContactUsButton = styled(ContactButton)`
  .non-decoration {
    text-decoration: none !important;
    color: black;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: auto;
    padding: 0.6rem 1rem;
    width: auto;
  }
`;

export const Title = styled(HeroTitle)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    line-height: 3.5rem;
  }
`;

export const AbsoluteMobileWrapper = styled.div`
  position: absolute;
  top: -3%;
  padding-top: 10rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    padding: 1rem 3rem;
    margin-top: -2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
