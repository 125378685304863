import React from 'react';

import i18n from '@/translate/i18n';
import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { Text } from '@/components/atoms/Typography/styles';
import bankIcon from '@/assets/central-bank.png';

import { ContentWrapper, TextSpacing } from './styles';

function KycSection() {
  return (
    <DefaultContainer bgColor="light">
      <ContentWrapper>
        <img src={bankIcon} alt="central bank" />
        <TextSpacing>
          <Text size="xl">{i18n.t('kycSection.title')}</Text>
        </TextSpacing>
      </ContentWrapper>
    </DefaultContainer>
  );
}

export default KycSection;
