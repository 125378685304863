import React from 'react';

import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';

import { Container, ContentWrapper } from './styles';

import 'swiper/css';
import 'swiper/css/pagination';

function FlashBenefitsSection() {
  return (
    <Container bgColor="dark">
      <ContentWrapper>
        <Text size="5xl" color="light" lineHeight="3.5rem">
          {i18n.t('flashBenefitsSection.title')}
        </Text>
      </ContentWrapper>
    </Container>
  );
}

export default FlashBenefitsSection;
