import React from 'react';

import { Container, ContentWrapper, MobileContainer } from './styles';
import descriptionA from '@/assets/celebrating.png';
import GridSection from '@/components/molecules/GridSection/index';
import useIsMobile from '@/hook/useIsMobile';
import i18n from '@/translate/i18n';
import { HideInDesktop } from '../MerchantDescriptionSection/styles';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import { ExternalLink } from '@/components/atoms/Typography/styles';

function Awards() {
  const isMobile = useIsMobile();
  return (
    <Container>
      {isMobile && (
        <MobileContainer>
          <p size="xl">{i18n.t('awardsSection.title')}</p>
          <img src={descriptionA} alt="person celebrating" width="250" />
        </MobileContainer>
      )}
      <ContentWrapper>
        <GridSection
          mobileTitleDisposition="95"
          imagePosition={{ top: '-15%', left: 'auto', right: '0%' }}
          image={!isMobile && descriptionA}
          imageMaxWidth="500px"
          title={!isMobile ? 'awardsSection.title' : null}
          buttonTitle="awardsSection.howToButton"
          data={[
            'awardsSection.advantageA',
            'awardsSection.advantageB',
            'awardsSection.advantageC',
            'awardsSection.advantageD',
          ]}
        />
      </ContentWrapper>
      <HideInDesktop className="mobile-btn">
        <ExternalLink href="mailto:help@vpag.com" target="_blank">
          <ContactButton>{i18n.t('awardsSection.createAccount')}</ContactButton>
        </ExternalLink>
      </HideInDesktop>
    </Container>
  );
}

export default Awards;
